"use strict";

import initModulesInScope from '@elements/init-modules-in-scope';

export function initInScope($scope) {

    let $lists = $scope.find('.js-template-item-list');

    console.log("HERE_", $lists, $lists[0]);

    $lists.each(function() {
        console.log("FOREACH");
        console.log($(this));
        let $list = $(this);
        let newId = 2;
        let $wrapper = $list.closest('.js-template-item-list__wrapper');
        let $addButton = $wrapper.find('.js-template-item-list__add');
        let name = $list.attr('data-template-item-list-name') || 'items';

        console.log($wrapper, $addButton, name);

        let $deleteButtons = $('.js-template-item-list__delete');

        $addButton.on('click', function(evt) {
            console.log("CLICKED");
            evt.preventDefault();

            let $template = $($list.find('.js-template-item-list__template').html());
            console.log($template);
            let $templateNew = $template.clone();

            renderFormItem($templateNew, newId);

            $list.append($templateNew);

            initModulesInScope($lists);

            newId++;
            $templateNew.find('.js-template-item-list__delete').on('click', deleteItem);


        });

        $deleteButtons.on('click', deleteItem);

        function deleteItem() {
            $(this).closest('.js-template-item-list__item').remove();

            $('.js-template-item-list__item').each(function() {
                renderFormItem($(this), getElementIndex(this));
            });

            newId--;
        }

        function renderFormItem($template, newId) {
            $template.find('.js-template-item-list__form-item').each(function (index) {
                let $templateNew = $(this);


                let $itemInput = $templateNew.find('.js-template-item-list__item-input');

                //needed for datepicker
                let $itemElementWithId = $templateNew.find('.js-template-item-list__item-id');
                if($itemElementWithId) {
                    let itemElementId = $itemElementWithId.attr('id');
                    if(!$itemElementWithId.attr('data-template-item-list-default-id')) {
                        $itemElementWithId.attr('data-template-item-list-default-id', $itemElementWithId.attr('id'));
                    } else {
                        itemElementId = $itemElementWithId.attr('data-template-item-list-default-id');
                    }
                    $itemElementWithId.attr('id', itemElementId+'-'+newId);
                }

                let itemName = $itemInput.attr('name');

                if(!$itemInput.attr('data-template-item-list-default-name')) {
                    $itemInput.attr('data-template-item-list-default-name', $itemInput.attr('name'));
                } else {
                    itemName = $itemInput.attr('data-template-item-list-default-name');
                }

                let $itemLabel = $templateNew.find('.js-template-item-list__item-label');


                $itemInput.attr('name', `${name}[${newId}][${itemName}]`);

                if($itemInput.attr('type') === 'radio') {
                    $itemLabel.attr('for', $itemInput.attr('id')+'-'+newId);
                    $itemInput.attr('id', $itemInput.attr('id')+'-'+newId);
                } else {
                    $itemLabel.attr('for', itemName+'-'+newId);
                    $itemInput.attr('id', itemName+'-'+newId);
                }
            });

            // needed for conditional-form if data-name should be changed
            let $itemElementWithName = $template.find('.js-template-item-list__item-name');
            $itemElementWithName.each(function() {
                let $item = $(this);
                let itemElementName = $item.attr('data-name');

                if(!$item.attr('data-template-item-list-default-name')) {
                    $item.attr('data-template-item-list-default-name', $item.attr('data-name'));
                } else {
                    itemElementName = $item.attr('data-template-item-list-default-name');
                }

                $item.attr('data-name', `${name}[${newId}][${itemElementName}]`);
            })


            $template.find('.js-template-item-list__item-count').html(newId);
        }

        function getElementIndex(element) {
            return [...element.parentNode.children].indexOf(element);
        }
    })
}

export function init() {
    let $nav = $('.js-portal-slide-scroll__nav');
    let $navCurrent = $('.js-portal-slide-scroll__nav-current');
    let $navTotal = $('.js-portal-slide-scroll__nav-total');
    const SLIDE_SELECTOR = '.js-portal-slide-scroll__slide';
    let $nextButton = $('.js-portal-slide-scroll__next');

    // generate nav
    let slideCount = $(SLIDE_SELECTOR).length;
    $navTotal.text(slideCount.toString().padStart(2, '0'));

    let $mover = $(`<div></div>`);
    let slidesMarkup = '';
    for (let i = 0; i < slideCount; i++) {
        slidesMarkup += `<div>${(i + 1).toString().padStart(2, '0')}</div>`;
    }

    $mover.css({
        'transition': 'transform 190ms ease'
    });

    $mover.append(slidesMarkup);
    $navCurrent.append($mover);

    $nav.attr('hidden', slideCount > 1 ? null: 'hidden');

    // init full page scrlling
    let slider;
    import('fullpage.js').then(({default: fullpage}) => {
        slider = new fullpage('.js-portal-slide-scroll', {
            licenseKey: '43B53076-621447A7-B15EE634-83A37056',
            loopBottom: false,
            loopTop: false,
            continuousVertical: true,
            sectionSelector: SLIDE_SELECTOR,
            normalScrollElements: '.nav-overlay, .booking-overlay, .cookie-modal',
            onLeave: (current, next) => {
                next.item.style.transition = 'none';
                next.item.style.opacity = .3;

                next.item.offsetHeight; // force redraw

                next.item.style.transition = 'opacity 500ms 300ms ease';
                next.item.style.opacity = 1;
                current.item.style.transition = 'opacity 500ms ease';
                current.item.style.opacity = .3;
                
                $mover.css('transform', `translateY(-${100 / slideCount * next.index}%)`);
            }
        });

        // next button
        $nextButton.on('click', function () {
            slider.moveSectionDown();
        });

        $nextButton.attr('hidden', slideCount > 1 ? null: 'hidden');
    });
}
"use strict";
import loadScript from "@elements/load-script";

export function init () {
    window.onloadCallback = function () {
        $('.js-recaptcha').each(function () {
            let $this = $(this);

            grecaptcha.render($this.find('.js-recaptcha__placeholder')[0], {
                'sitekey': $this.data('sitekey'),
                'callback': (...params) => reCaptchaVerify($this, ...params)
            });
        });
    };

    loadRecaptchaAPI();
}

export function loadRecaptchaAPI() {
    return loadScript("https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit");
}

function reCaptchaVerify($element, resp) {
    $element.find('.js-recaptcha__input')
        .val(resp)
        .trigger('input')
        .trigger('change');
}
export function init() {
    let $overlay = $('.js-overlay');
    let $open = $('.js-overlay__open');
    let $close = $overlay.find('.js-overlay__close');

    $open.on('click', () => {
        $overlay.filter($overlay.data('target')).addClass('is-visible');
    });

    $close.on('click', () => {
        $overlay.removeClass('is-visible');
    });

    if (_config.overlayInitialyOpen) {
        $overlay.filter(_config.overlayInitialyOpen).addClass('is-visible');
    }
}
import {getServicePermission} from "@elements/cookie-permissions";

export function initInScope ($scope) {
    let $forms = $scope.find('.js-ga-cross-domain-tracking-form');

    $forms.on('submit', function (evt) {
        let $form = $(this);

        if (getServicePermission('googleanalytics')) {
            let ga = window['ga'];
            if (window['ga']) {
                if (ga.getAll && ga.getAll().length) {
                    let trackers = ga.getAll();
                    let url = ($form.attr('action') || (location.origin + location.pathname));
                    url += url.indexOf('?') < 0 ? '?': '&';
                    url += $.param($form.serializeArray());

                    $.each(trackers, function (index, tracker) {
                        var linker = new window.gaplugins.Linker(tracker);
                        url = linker.decorate(url);
                    });

                    evt.preventDefault();
                    window.open(url, '_blank');
                }
            }
        }
    });
}
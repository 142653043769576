import 'jquery-menu-aim';

export function init () {
    let $element = $('.js-nav');
    let $toggle = $element.find('.js-nav__toggle');

    // if uri has the param show-hotels-initially open the navbar initially
    if (window.location.href.indexOf('show-hotels-initially') > -1) {
        if ($element.hasClass('is-open')) {
            $element.removeClass('is-open');
            $toggle.removeClass('is-open');
        } else {
            $element.addClass('is-open');
            $toggle.addClass('is-open');
        }

        let newUrl = window.location.href.replace(/&?show-hotels-initially/g, '');
        window.history.replaceState({}, document.title, newUrl);
    }

    $element.find('.js-nav__toggle').on('click', function () {
        if ($element.hasClass('is-open')) {
            $element.removeClass('is-open');
            $toggle.removeClass('is-open');
        } else {
            $element.addClass('is-open');
            $toggle.addClass('is-open');
        }
    });

    let $mainNav = $('.js-nav__main-list');
    let $mainNavItems = $element.find('.js-nav__main-list-item');
    let $mainNavLinks = $element.find('.js-nav__main-list-link');

    if (matchMedia('(max-width: 767px)').matches) {
        $mainNavLinks.on('click', function (evt) {
            evt.preventDefault();
            $(this).closest($mainNavItems).toggleClass('is-open');
        })
    } else {
        $mainNavLinks.on('touchend', function (evt) {
            if (window.TouchEvent && evt.originalEvent instanceof TouchEvent) {
                evt.preventDefault();
                evt.stopImmediatePropagation();

                $mainNavItems.removeClass('is-open');
                $(this).closest($mainNavItems).addClass('is-open');
            }
        });

        $mainNav.menuAim({
            rowSelector: ".js-nav__main-list-item",
            activate: element => {  // fired on row activation
                $mainNavItems.removeClass('is-open');
                $(element).addClass('is-open');
            },
            deactivate: element => $(element).removeClass('is-open')  // fired on row deactivation
        });
    }
}

export function closeNavOverlay() {
    let $element = $('.js-nav');
    let $toggle = $element.find('.js-nav__toggle');

    $element.removeClass('is-open');
    $toggle.removeClass('is-open');
}
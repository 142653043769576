import {closeNavOverlay} from "./nav";
import {translate} from "@elements/translations";
import {ISOStringToDate} from "@elements/date-utils";
import {setMaxDate, setMinDate} from "./rangeDatepicker";

let isTouchDevice = matchMedia('(hover: none) and (pointer:coarse)').matches;

export function init() {
    let $toggle = $('.js-booking-overlay__toggle');
    let $overlay = $('.js-booking-overlay');
    let $datepicker = $('.js-booking-overlay__datepicker');
    let $form = $overlay.find('form');
    let $baseAction = $form.attr('action');

    $toggle.on('click', toggleOverlay);

    if (window.location.search.indexOf('openBookingOverlay') >= 0) {
        openOverlay();
    }

    function toggleOverlay() {
        $overlay.toggleClass('is-open');

        if ($overlay.hasClass('is-open')) {
            closeNavOverlay();
            $overlay.focus();
        }
    }

    function openOverlay() {
        $overlay.addClass('is-open');
        closeNavOverlay();
        $overlay.focus();
    }

    let from, to;
    $datepicker.on('selected', (evt, {from:newFrom, to:newTo, hover}) => {
        from = newFrom;
        to = newTo;

        updateNextButton();
    });

    $datepicker.on('selected hovered', (evt, {from, to, hover}) => {
        updateCtaLabel({from, to, hover});
        updateDateLabel({from, to, hover});
    });
    let $dateLabel = $('.js-booking-overlay__date-label');
    let dateLabelDefault = $dateLabel.text();

    function updateDateLabel({from, to, hover}) {
        if (from) {
            if (to) {
                $dateLabel.text(`${formatDateShort(from)} - ${formatDateLong(to)}`);
            } else if (hover) {
                if (hover <= from) {
                    $dateLabel.text(`${formatDateLong(hover)}`);
                } else {
                    $dateLabel.text(`${formatDateShort(from)} - ${formatDateLong(hover)}`);
                }
            } else {
                $dateLabel.text(`${formatDateLong(from)}`);
            }
        } else {
            $dateLabel.text(dateLabelDefault);
        }
    }

    let $ctaLabel = $('.js-booking-overlay__cta-label');
    function updateCtaLabel({from, to, hover}) {
        if (from) {
            $ctaLabel.attr('hidden', (isTouchDevice && !to) ? 'hidden': null);

            if (to) {
                $ctaLabel.text(getCtaLabel(from, to, selectedHotel.name));
            } else if (hover) {
                if (hover <= from) {
                    $ctaLabel.text(getCtaLabel(from, from, selectedHotel.name));
                } else {
                    $ctaLabel.text(getCtaLabel(from, hover, selectedHotel.name));
                }
            } else {
                $ctaLabel.text(getCtaLabel(from, from, selectedHotel.name));
            }
        } else {
            $ctaLabel.attr('hidden', 'hidden');
        }
    }

    // Hotel selection
    let selectedHotel = null;
    let $hotelSelection = $('.js-booking__hotel-selection');
    let $hotelLabel = $('.js-booking-overlay__hotel-label');
    let hotelLabelDefault = $hotelLabel.text();

    {
        let $initialSelection = $hotelSelection.find(':input:checked');
        if ($initialSelection.length) {

            let $stepTwoButton = $('.js-booking-bar__go-to-step[data-step="1"]');
            let $overlayToggle = $('.js-booking-overlay__toggle');

            if($stepTwoButton.length) {
                $overlayToggle.on('click', function() {
                    $stepTwoButton[0].click();
                });
            }

            selectedHotel = {
                name: $initialSelection.closest($hotelSelection).text().trim(),
                id: $initialSelection.attr('value'),
                campagne: $hotelSelection.data('hotel-selection-discount')
            };
        }
    }

    $hotelSelection.on('click', function(evt) {
        // prevent the event, because otherwise the label click triggers an additional click event o the input
        // and this listener would be called twice
        evt.stopImmediatePropagation();
        evt.preventDefault();
        let $input = $(this).find(':input');
        $input.prop('checked', true);

        selectedHotel = {
            name: $(this).text().trim(),
            id: $input.attr('value'),
            campagne: $(this).data('hotel-selection-discount')
        };

        let dates =  _config.bookingDatesByHotelId[$input.attr('value')];

        if (dates) {
            setMinDate($datepicker, dates.min ? ISOStringToDate(dates.min): new Date());
            setMaxDate($datepicker, dates.max ? ISOStringToDate(dates.max): null);
        } else {
            setMinDate($datepicker, new Date());
            setMaxDate($datepicker, null);
        }

        goToStep(++currentStep);

        updateCtaLabel({from, to});
    });

    if ($hotelSelection.length === 1) {
        let $input = $hotelSelection.find(':input');

        selectedHotel = {
            name: $(this).text().trim(),
            id: $input.attr('value'),
            campagne: $(this).data('hotel-selection-discount'),
        };

        let dates =  _config.bookingDatesByHotelId[$input.attr('value')];

        if (dates) {
            setMinDate($datepicker, dates.min ? ISOStringToDate(dates.min): new Date());
            setMaxDate($datepicker, dates.max ? ISOStringToDate(dates.max): null);
        } else {
            setMinDate($datepicker, new Date());
            setMaxDate($datepicker, null);
        }

    }

    // steps
    let validationsByStep = {
        0: () => !!$hotelSelection.find(':input:checked').length,
        1: () => !!from && !!to
    };

    function isValidTil(step) {
        let isValid = true;
        for (let i = 0; i < step; i++) {
            if (!validationsByStep[i]()) {
                isValid = false;
                break;
            }
        }
        return isValid;
    }

    let $steps = $('.js-booking__step');

    let orderedSteps = $steps.toArray()
        .reduce((acc, cur) => {acc[+cur.dataset.step] = cur; return acc;}, {});

    let currentStep = _config.bookingOverlayStartStep || 0;
    let $stepButtons = $('.js-booking-bar__go-to-step');
    let orderedStepButtons = $stepButtons.toArray()
        .reduce((acc, cur) => {acc[+cur.dataset.step] = cur; return acc;}, {});
    
    $stepButtons.on('click', function () {
        currentStep = $(this).data('step');
        goToStep(currentStep);
    });

    let $nextButton = $('.js-booking-overlay__next-step');
    $nextButton.on('click', (evt) => {
        if (currentStep === 1) {
            if (!isValidTil(currentStep + 1)) {
                evt.preventDefault();
            } else {
                // submit
                let $form = $overlay.find('form');
                let action = $form.attr('action');
                if(!action.includes('/' + selectedHotel.id)) {

                    if(selectedHotel.campagne !== 0){
                        $form.attr('action', $baseAction + '/' + selectedHotel.id + "?discount=" + selectedHotel.campagne);
                        console.log('if');
                    }else{
                        $form.attr('action', $baseAction + '/' + selectedHotel.id);
                        console.log('else');
                    }
                }
            }
        } else {
            goToStep(++currentStep);
            evt.preventDefault();
        }
    });

    function updateNextButton() {
        $nextButton.attr('disabled', isValidTil(currentStep + 1) ? null  : 'disabled');
    }

    let $backButtons = $('.js-booking-bar__prev-step');
    $backButtons.on('click', (evt) => {
        goToStep(--currentStep);
    });

    goToStep(currentStep);

    function goToStep(step) {
        $steps.attr('hidden', 'hidden');
        $(orderedSteps[step]).attr('hidden', null);

        // step buttons
        $stepButtons.attr('disabled', 'disabled').removeClass('is-active');
        Object.entries(orderedStepButtons)
            // .filter(([currentStep]) => currentStep <= step)
            .map(([currentStep, element]) => {
                $(element).attr('disabled', isValidTil(currentStep) ? null: 'disabled');

                return [currentStep, element];
            })
            .filter(([currentStep]) => +currentStep === +step)
            .forEach(([currentStep, element]) => {
                $(element).addClass('is-active');
            });

        // add overlay step class
        Object.entries(orderedStepButtons)
            .forEach(([currentStep]) => {
                $overlay.removeClass(`is-in-step-${currentStep}`);
            });

        $overlay.addClass(`is-in-step-${step}`);

        // back button
        $backButtons.attr('hidden', step === 0 ? 'hidden': null );

        // next step button
        updateNextButton();

        // hotel label
        $hotelLabel.text(step === 0 ? hotelLabelDefault : selectedHotel.name);


        //show campagne in Head
        let $campagneArea = $('.js-booking-overlay__campagne-area');
        let $bookingHeadBar = $('.js-booking-overlay__campaigne-head');

        if(selectedHotel !== null){
            if(selectedHotel.campagne === 0 || step === 0){
                $campagneArea.addClass('d-none');
                $bookingHeadBar.removeClass('head-bar--has-campagne');
            }else{
                $campagneArea.removeClass('d-none');
                $bookingHeadBar.addClass('head-bar--has-campagne');
            }
            let $campagneListItem = $('.js-campagne-list-item');
            $campagneListItem.text(step === 0 ? '' : selectedHotel.campagne);
        }
    }
}


function formatDateLong(date) {
    return date.toLocaleDateString(_config.lang, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
}
function formatDateShort(date) {
    return date.toLocaleDateString(_config.lang, {
        month: '2-digit',
        day: '2-digit'
    });
}

function getCtaLabel(from, to, hotelName = '') {
    if (!from) {
        return '';
    }

    to = to || from;

    let nights = ((to.getTime() - from.getTime()) / (24 * 60 * 60 * 1000));
    return translate('booking-overlay.cta-text')
        .replace('[hotel]', ' ' + hotelName)
        .replace('[nights]', `${nights} ${nights === 1
            ? translate('booking-overlay.night')
            : translate('booking-overlay.nights')
        }`);
}
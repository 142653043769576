export function init() {
    Promise.all([import('pixi.js'), import('gsap'), import('fullpage.js'), import('fullpage.js/vendors/scrolloverflow.js')])
        .then(([PIXI, {TweenMax, TimelineLite, TweenLite, Power2, Expo}, {default: fullpage}]) => {
            let sliderSelector = '.js-liquid-slider';
            let $nextButton = $('.js-liquid-slider__next');
            let $slides = $('.js-liquid-slider__slide');
            let $mediaElements = $slides.find('.js-liquid-slider__media');
            let $mediaPlaceholderElements = $slides.find('.js-liquid-slider__media-placeholder');
            let sprites = $mediaElements.toArray();
            let $canvas = $('.js-liquid-slider__canvas');

            $canvas.on('initialized', () => {
                $mediaElements.addClass('invisible');
                $mediaPlaceholderElements.addClass('invisible');
                $canvas.removeClass('invisible');
            });

            let canvasSlideShow = new CanvasSlideshow($canvas, {
                sprites: sprites,
                displacementImage: '/static/build/img/distortion/clouds.jpg',
                autoPlay: true,
                autoPlaySpeed: [0.3, 0.3],
                displaceScale: [300, 200],
                displaceAutoFit: true,
                fullScreen: true
            });

            // init full page
            let slider = new fullpage(sliderSelector, {
                licenseKey: '43B53076-621447A7-B15EE634-83A37056',
                loopBottom: false,
                loopTop: false,
                scrollOverflow: true,
                scrollOverflowOptions: { click: false },
                sectionSelector: '.js-liquid-slider__slide',
                normalScrollElements: '.nav-overlay, .booking-overlay',
                onLeave: (current, next) => {
                    canvasSlideShow.startIdleAnimation(); // start idle animation on first scroll

                    canvasSlideShow.moveSlider(next.index);
                    $mover.css('transform', `translateY(-${100 / slideCount * next.index}%)`);

                    if (next.index + 1 >= slideCount) {
                        $nextButton.addClass('is-hidden');
                    } else {
                        $nextButton.removeClass('is-hidden');
                    }
                }
            });

            // window.liqudisSlider = slider;
            // console.log('test');
            // Reinit after some time, to make sure fullpages is rebuild after the css was loaded.
            // let timeout1 = setInterval(function () {
            //     // $(window).trigger('resize');
            //     // console.log('resize 1');
            //     let activeSlide = slider.getActiveSection().index;
            //     slider.reBuild();
            //     slider.silentMoveTo(activeSlide);
            //     console.log('rebuild 1');
            //     clearTimeout(timeout1);
            // }, 500);
            //
            // let timeout2 = setInterval(function () {
            //     console.log('resize 2');
            //     let activeSlide = slider.getActiveSection().index;
            //     slider.reBuild();
            //     slider.silentMoveTo(activeSlide);
            //     clearTimeout(timeout2);
            // }, 1500);


            // nav
            let $nav = $('.js-liquid-slider__nav');
            let $navCurrent = $('.js-liquid-slider__nav-current');
            let $navTotal = $('.js-liquid-slider__nav-total');
            const SLIDE_SELECTOR = '.js-liquid-slider__slide';

            // generate nav
            let slideCount = $(SLIDE_SELECTOR).length;
            $navTotal.text(slideCount.toString().padStart(2, '0'));

            let $mover = $(`<div></div>`);
            let slidesMarkup = '';
            for (let i = 0; i < slideCount; i++) {
                slidesMarkup += `<div>${(i + 1).toString().padStart(2, '0')}</div>`;
            }

            $mover.css({
                'transition': 'transform 190ms ease'
            });

            $mover.append(slidesMarkup);
            $navCurrent.append($mover);

            $nav.attr('hidden', slideCount > 1 ? null: 'hidden');

            // next button
            $nextButton.on('click', function () {
                slider.moveSectionDown();
            });

            $nextButton.attr('hidden', slideCount > 1 ? null: 'hidden');


            function CanvasSlideshow($canvas, options) {
                //  SCOPE
                /// ---------------------------
                let that = this;


                //  OPTIONS
                /// ---------------------------
                options = options || {};
                options.stageWidth = 1920; // has to match the thumbnail
                options.stageHeight = 1080;  // has to match the thumbnail
                options.pixiSprites = options.hasOwnProperty('sprites') ? options.sprites : [];
                options.autoPlay = options.hasOwnProperty('autoPlay') ? options.autoPlay : true;
                options.autoPlaySpeed = options.hasOwnProperty('autoPlaySpeed') ? options.autoPlaySpeed : [10, 3];
                options.fullScreen = options.hasOwnProperty('fullScreen') ? options.fullScreen : true;
                options.displaceScale = options.hasOwnProperty('displaceScale') ? options.displaceScale : [200, 70];
                options.displacementImage = options.hasOwnProperty('displacementImage') ? options.displacementImage : '';
                options.displaceAutoFit = options.hasOwnProperty('displaceAutoFit') ? options.displaceAutoFit : false;
                options.wacky = options.hasOwnProperty('wacky') ? options.wacky : false;
                options.displaceScaleTo = (options.autoPlay === false) ? [0, 0] : [20, 20];


                //  PIXI VARIABLES
                /// ---------------------------
                let renderer = new PIXI.autoDetectRenderer({
                    width: options.stageWidth,
                    height: options.stageHeight,
                    view: $canvas[0],
                    transparent: true,
                    autoDensity: true
                });

                let stage = new PIXI.Container();
                let slidesContainer = new PIXI.Container();
                let displacementSprite = new PIXI.Sprite.from(options.displacementImage);
                let displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);


                //  SLIDES ARRAY INDEX
                /// ---------------------------
                this.currentIndex = 0;


                /// ---------------------------
                //  INITIALISE PIXI
                /// ---------------------------
                this.initPixi = function () {
                    // Add child container to the main container
                    stage.addChild(slidesContainer);

                    // Enable Interactions
                    // stage.interactive = true;

                    displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;


                    // Set the filter to stage and set some default values for the animation
                    stage.filters = [displacementFilter];

                    displacementSprite.scale.x = 2;
                    displacementSprite.scale.y = 2;

                    // PIXI tries to fit the filter bounding box to the renderer so we optionally bypass
                    // displacementFilter.autoFit = options.displaceAutoFit;

                    stage.addChild(displacementSprite);
                };


                /// ---------------------------
                //  LOAD SLIDES TO CANVAS
                /// ---------------------------
                this.loadPixiSprites = function (sprites) {
                    let rSprites = options.sprites;

                    for (let i = 0; i < rSprites.length; i++) {

                        let image = new PIXI.Sprite();

                        // mostly need for smaller videos; if these values are not set the video is not fullscreen
                        image.width = options.stageWidth;
                        image.height = options.stageHeight;

                        if (i !== 0) {
                            TweenMax.set(image, {alpha: 0});
                        }

                        slidesContainer.addChild(image);
                    }
                };


                /// ---------------------------
                //  DEFAULT RENDER/ANIMATION
                /// ---------------------------
                let ticker = new PIXI.Ticker();

                ticker.autoStart = options.autoPlay;
                displacementFilter.scale.x = 0;
                displacementFilter.scale.y = 0;

                ticker.add(function (delta) {
                    if (that.idleAnimationRunning) {
                        displacementSprite.x += options.autoPlaySpeed[0] * delta;
                        displacementSprite.y += options.autoPlaySpeed[1];
                    }

                    renderer.render(stage);
                });

                this.idleAnimationRunning = false;
                this.startIdleAnimation = function () {
                    if (this.idleAnimationRunning) {
                        return;
                    }

                    displacementFilter.scale.x = 20;
                    displacementFilter.scale.y = 20;

                    this.idleAnimationRunning = true;
                };


                /// ---------------------------
                //  TRANSITION BETWEEN SLIDES
                /// ---------------------------
                let slideImages = slidesContainer.children;
                this.moveSlider = function (newIndex) {
                    let that = this;

                    if (this.baseTimeline) {
                        that.baseTimeline.pause();
                        that.baseTimeline.clear();
                        that.baseTimeline = null;
                    }

                    let baseTimeline = new TimelineLite({
                        onComplete: function () {
                            that.currentIndex = newIndex;
                            if (options.wacky === true) {
                                displacementSprite.scale.set(1);
                            }
                            that.baseTimeline = null;
                        }, onUpdate: function () {
                            if (options.wacky === true) {
                                displacementSprite.rotation += baseTimeline.progress() * 0.02;
                                displacementSprite.scale.set(baseTimeline.progress() * 3);
                            }

                        }
                    });

                    this.baseTimeline = baseTimeline;

                    if (slideImages[newIndex]) {
                        slideImages
                            .filter((_, index) => index !== newIndex)
                            .map((slideImage) => {
                                baseTimeline.add(
                                    TweenLite.to(slideImage, .9, {
                                        alpha: 0,
                                        ease: Power2.easeOut
                                    }),
                                    .2
                                );
                            });

                        baseTimeline.add(
                            TweenLite.to(slideImages[newIndex], 1.4, {
                                alpha: 1,
                                ease: Power2.easeOut,
                            }),
                            .4
                        );
                    }

                    let displaceScale = [
                        (options.displaceScale[0] / 2 * Math.random()) + (options.displaceScale[0] / 2) * (Math.random() < .5 ? -1 : 1),
                        (options.displaceScale[1] / 2 * Math.random()) + (options.displaceScale[1] / 2) * (Math.random() < .5 ? -1 : 1)
                    ];

                    baseTimeline
                        .to(displacementFilter.scale, 1.1, {
                            x: displaceScale[0],
                            y: displaceScale[1],
                            ease: Power2.easeOut
                        }, 0)
                        .to(displacementFilter.scale, 1.1, {
                            x: options.displaceScaleTo[0],
                            y: options.displaceScaleTo[1],
                            ease: Expo.easeOut
                        }, 0.7);
                };

                /// ---------------------------
                //  INIT FUNCTIONS
                /// ---------------------------
                this.init = function () {
                    that.initPixi();
                    that.loadPixiSprites(options.pixiSprites);

                    if (options.fullScreen === true) {
                        window.addEventListener("resize", function (event) {
                            scaleToWindow(renderer.view);
                        });
                        scaleToWindow(renderer.view);
                    }

                    $canvas.trigger('initialized');
                };

                /// ---------------------------
                //  CENTER DISPLACEMENT
                /// ---------------------------
                displacementSprite.anchor.set(0.5);
                displacementSprite.x = renderer.view.width / 2;
                displacementSprite.y = renderer.view.height / 2;


                /// ---------------------------
                //  START
                /// ---------------------------
                this.init();


                /// ---------------------------
                //  HELPER FUNCTIONS
                /// ---------------------------
                function scaleToWindow(canvas) {
                    if (options.stageWidth / options.stageHeight < window.innerWidth / window.innerHeight) {
                        canvas.style.width = "100%";
                        canvas.style.height = "auto";
                    } else {
                        canvas.style.width = "auto";
                        canvas.style.height = "100%";
                    }
                }
            }
        });
}

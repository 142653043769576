import {add} from '@elements/scroll-animations';

export function initInScope($scope) {
    if (!matchMedia('(max-width: 767px)').matches) {
        add(
            $scope.find('.js-fade-in'),
            calculateAnimationProgress,
            setAnimationProgress
        );
    }
}

function calculateAnimationProgress(element) {
    const rect = element.getBoundingClientRect();
    const start = window.innerHeight / 8 * 7;
    const end = rect.height * -1;

    return 1 - Math.max(
        Math.min(
            (rect.top - end) / (start - end),
            1
        ),
        0
    );
}

function setAnimationProgress(element, progress) {
    element.style.opacity = Math.min(1, progress * 2  + .1);
    element.style.transform = `translateY(${((progress * 8) - 4) * -1}rem)`;
}

export function init () {
    if (matchMedia('(hover: none) and (pointer:coarse)').matches) {
        return;
    }

    let $cursor = $('.js-custom-cursor');

    document.addEventListener("mousemove", e => {
        $cursor.css('transform', `translate(${e.clientX}px, ${e.clientY}px)`);
    });

    document.addEventListener('mousedown', e => {
        $cursor.addClass('is-active');
    });
    document.addEventListener('mouseup', e => {
        $cursor.removeClass('is-active');
    });

    let interactiveElements = [':input', 'label', 'a', 'button']
        .map(x => x + ':not([disabled])');

    $(document.body).on('mouseenter', interactiveElements.join(', '), () => {
        $cursor.addClass('is-hover');
    });

    $(document.body).on('mouseleave', interactiveElements.join(', '), () => {
        $cursor.removeClass('is-hover');
    });

    $cursor
        .removeClass('is-hover')
        .removeClass('is-active');
}
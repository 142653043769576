import {translate} from "@elements/translations";

export function init() {
    let $btn = $('.js-change-show-more');
    let $collapse = $('.collapse');

    $btn.on('click', function (e){
        if($collapse.hasClass('show')){
            $btn.html(translate('content.Mehr lesen') + '<span class="collapse-btn__icon ml-2"></span>');
        }else{
            $btn.html(translate('content.Weniger lesen') + '<span class="collapse-btn__icon ml-2"></span>');
        }
    });
}
import $ from 'jquery';

export function init () {
    let $overlayImages = $('.js-play-video');
    $overlayImages.each( function() {
        let $button = $('.js-play-video__button');
        let $this = $(this);
        $button.on('click', function () {
            let $video = $this.find('.js-play-video__video');
            $('.js-play-video').addClass('overlay-hide');
            $video.get(0).play();
        });
    });
}

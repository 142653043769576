import $ from 'jquery';

import asyncAppend from "@elements/async-append";
import fetch from '@elements/fetch';

export function initInScope($scope) {
    $scope.find('.js-async-append__start').on('click', (evt) => {
        let url = $(evt.target).data('content-url');
        let $target = $(evt.target).closest('.js-async-append__result');
        let $wrapper = $(evt.target).closest('.js-async-append__wrapper');
        let $loading = $wrapper.find('.js-async-append__loading');
        let $notifications = $wrapper.find('.js-async-append__notifications');
        asyncAppend({
            $target,
            $loading,
            $notifications
        }, fetch(url));
    });
}
// Inspiration
// https://codepen.io/cojdev/pen/PjYPKv
// https://curran.github.io/HTML5Examples/canvas/waveSimulation1D/index.html

import {onEnterViewPort} from "@elements/viewport-utils";

export function init() {
    if (!matchMedia('(min-width: 768px) and (pointer: fine)').matches) {
        return;
    }
    
    let canvas = document.querySelector(".js-wave-animation");
    let c = canvas.getContext("2d");

    let pullStrength = 0.01;
    let dampeningFactor = 0.96;
    let initialHeight = .8;
    let cells = [];
    let gridSize = 10;
    let conservationOfMassCorrection = 0;
    let cellWidth = 1 / (gridSize - 1) * canvas.width;
    let mouseX, mouseY, mouseOver;
    let animate = false;
    let canvasWidth = canvas.width;
    let canvasHeight = canvas.height;

// This function executes once per animation frame
    function executeFrame() {
        if (animate) {
            requestAnimationFrame(executeFrame);
        }

        clearCanvas();
        drawWave();
        // drawPath();
        // drawNodes();
        iterateSimulation();
        executeMouseInteraction();
    }

// Initialize the water height
    for (let i = 0; i < gridSize; i++) {
        let initialWaveIndex =  Math.floor(gridSize / 100 * 70);
        cells.push({
            // for an initial wave:
            height: (i === initialWaveIndex) ? 1.4 : initialHeight,
            velocity: 0
        });
    }

    function clearCanvas() {
        canvas.width = window.innerWidth;
        cellWidth = 1 / (gridSize - 1) * canvas.width;
        canvasWidth = window.innerWidth;
    }

    function calculateXValue(x) {
        return x / (gridSize - 1) * canvasWidth;
    }

    function calculateYValue(y) {
        return canvasHeight - (y * (canvasHeight - 25))
    }

    function drawWave() {
        c.beginPath();
        c.fillStyle = '#489aa6';
        c.moveTo(0, canvas.height);

        for (let i = 0; i < gridSize; i++) {
            let cell = cells[i];
            let x = calculateXValue(i);
            let y = calculateYValue(cell.height);

            if (i === 0) {
                c.lineTo(x, y);
            } else if (cells[i + 1]) {

                let nextCell = cells[i+1];
                let nextX = calculateXValue(i + 1);
                let nextY = calculateYValue(nextCell.height);
                // let nextX = (i + 1) / (gridSize - 1) * canvas.width;
                // let nextY = canvas.height - nextCell.height * canvas.height;
                c.quadraticCurveTo(x, y, diff(x, nextX), diff(y, nextY));
            } else {
                c.lineTo(x, y);
            }
        }

        c.lineTo(canvas.width, canvas.height);
        c.closePath();
        c.fill();
    }

    // function drawPath() {
    //     // Path
    //     c.beginPath();
    //     c.strokeStyle = 'black';
    //     c.moveTo(0, canvas.height);
    //     for (let i = 0; i < gridSize; i++) {
    //         let cell = cells[i];
    //         let x = i / (gridSize - 1) * canvas.width;
    //         let y = canvas.height - cell.height * canvas.height;
    //
    //         if (i === 0) {
    //             c.lineTo(x, y);
    //         } else if (cells[i + 1]) {
    //             let nextCell = cells[i +1];
    //             let nextX = (i + 1) / (gridSize - 1) * canvas.width;
    //             let nextY = canvas.height - nextCell.height * canvas.height;
    //             c.quadraticCurveTo(x, y, diff(x, nextX), diff(y, nextY));
    //         } else {
    //             c.lineTo(x, y);
    //         }
    //     }
    //
    //     c.lineTo(canvas.width, canvas.height);
    //     c.closePath();
    //     c.stroke();
    // }
    //
    // function drawNodes() {
    //     for (let i = 0; i < gridSize; i++) {
    //         let cell = cells[i];
    //         let x = i / (gridSize - 1) * canvas.width;
    //         let y = canvas.height - cell.height * canvas.height;
    //
    //         c.beginPath();
    //         c.fillStyle = 'blue';
    //         c.arc(x, y, 5, 0, 2 * Math.PI);
    //         c.fill();
    //
    //         if (cells[i + 1]) {
    //             let nextCell = cells[i + 1];
    //             let nextX = (i + 1) / (gridSize - 1) * canvas.width;
    //             let nextY = canvas.height - nextCell.height * canvas.height;
    //
    //             c.beginPath();
    //             c.fillStyle = 'lightblue';
    //             c.arc(diff(x, nextX), diff(y, nextY), 3, 0, 2 * Math.PI);
    //             c.fill();
    //         }
    //     }
    // }

// Increment the wave simulation:
// Neighboring cells pull on one another.
    function iterateSimulation() {
        let avgHeight = 0;
        for (let i = 0; i < gridSize; i++) {
            // center cell
            let c = cells[i];

            // left neighbor
            let l = cells[((i - 1) + gridSize) % gridSize];

            // right neighbor
            let r = cells[(i + 1) % gridSize];

            // pull toward neighbors
            c.velocity += pullStrength * (l.height - c.height);
            c.velocity += pullStrength * (r.height - c.height);

            // increment velocity
            c.height += c.velocity;

            // ensure conservation of mass
            c.height += conservationOfMassCorrection;

            // apply dampening
            c.velocity *= dampeningFactor;

            avgHeight += c.height;
        }
        avgHeight /= (gridSize - 1);

        conservationOfMassCorrection = initialHeight - avgHeight;
    }

// Pull the wave cell closest to the mouse
    function executeMouseInteraction() {
        if (mouseOver) {
            for (let i = 0; i < gridSize; i++) {
                let x = i / (gridSize - 1) * canvas.width;
                if (Math.abs(x - mouseX) < cellWidth) {
                    let cell = cells[i];
                    cell.height = 1 - mouseY / canvas.height;
                    cell.velocity = 0;
                }
            }
        }
    }

// Record when the mouse is moved
    canvas.addEventListener("mousemove", function (evt) {
        mouseX = evt.offsetX;
        mouseY = evt.offsetY;
        mouseOver = true;
    });
    
    canvas.addEventListener("mouseout", function (e) {
        mouseOver = false
    });

    onEnterViewPort($(canvas), function () {
        // Start animation
        animate = true;
        executeFrame();
    }, {
        offset: -55
    });

    executeFrame();
}

function diff (a, b) {
    return (b - a) / 2 + a;
}
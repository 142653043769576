import $ from 'jquery';

// import 'popper.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
 import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as consentOverlay from './scripts/consent-overlay';
app.modules.consentOverlay = consentOverlay.initInScope;

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as recaptcha from './scripts/recaptcha';
app.recaptcha = recaptcha.init;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as dynamicLightbox from './scripts/lightboxDynamic';
app.modules.dynamicLightbox = dynamicLightbox.init;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as anchorNav from '@elements/anchor-nav';
app.modules.anchorNav = anchorNav.createInitInScope({
    list: '.js-anchor-nav',
    listItem: '.js-anchor-nav__item'
},  {
    renderItem: function (text, anchor) {
        return '<li class="nav-item anchor-nav__item list-inline-item"><a class="nav-link js-smoothscroll__link" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
    },
    smoothScroll: true,
    scrollSpy: true,
    scrollSpyOffset: 122
});

import * as affix from '@elements/affix';
app.modules.affix = affix.initInScope;

import * as templateItemList from './scripts/templateItemList';
app.modules.templateItemList = templateItemList.initInScope;

import * as rangeDatepicker from './scripts/rangeDatepicker';
app.modules.rangeDatepicker = rangeDatepicker.initInScope;

import * as mobileHeadroom from './scripts/mobileHeadroom';
app.modules.mobileHeadroom = mobileHeadroom.initInScope;

import * as liquidSlider from './scripts/liquidSlider';
app.liquidSlider = liquidSlider.init;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as tracking from "@elements/tracking";
app.modules.tracking = tracking.initGtmEventTracking();

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as scrollMarquee from './scripts/scrollMarquee';
app.modules.scrollMarquee = scrollMarquee.initInScope;

import * as customCursor from './scripts/custom-cursor';
app.customCursor = customCursor.init;

import * as delayedfadeIn from './scripts/delayed-fade-in';
app.modules.delayedfadeIn = delayedfadeIn.initInScope;

import * as rightToLeftFadeIn from './scripts/right-to-left-fade-in';
app.modules.rightToLeftFadeIn = rightToLeftFadeIn.initInScope;

import * as fadeIn from './scripts/fade-in';
app.modules.fadeIn = fadeIn.initInScope;


import * as nav from './scripts/nav';
app.nav = nav.init;

import * as changeShowMore from './scripts/change-show-more-text';
app.changeShowMore = changeShowMore.init;

import * as bookingOverlay from './scripts/bookingOverlay';
app.bookingOverlay = bookingOverlay.init;

import * as asyncAppend from './scripts/async-append';
app.modules.asyncAppend = asyncAppend.initInScope;

import * as overlay from './scripts/overlay';
app.overlay = overlay.init;

import * as leadOverlay from './scripts/lead-overlay';
app.leadOverlay = leadOverlay.init;

import * as portalSlideScroll from './scripts/portal-slide-scroll';
app.portalSlideScroll  = portalSlideScroll.init;

import * as videoMute from './scripts/videoMute';
app.videoMute  = videoMute.init;

import * as videoOverlay from './scripts/video-overlay';
app.videoOverlay  = videoOverlay.init;

import * as waveAnimation from './scripts/waveAnimation';
app.waveAnimation = waveAnimation.init;

import * as mainCta from './scripts/mainCta';
app.modules.mainCta = mainCta.initInScope;

import * as smoothScroll from '@elements/smooth-scroll';
app.smoothScroll = () => smoothScroll.init({}, {
    link: '.js-smoothscroll__link, .js-smoothscroll__link-wrapper a'
});
app.modules.smoothScroll = smoothScroll.initInScope;

import * as dateInput from '@elements/date-input';
app.modules.dateInput = dateInput.initInScope;

// todo remove the next two pacakges (datepicker & datepickerRange) as soon as the IBE is live
import * as datepicker from '@elements/datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as datepickerRange from '@elements/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as gaCrossDomainTrackingForm from './scripts/gaCrossDomainTrackingForm';
app.modules.gaCrossDomainTrackingForm = gaCrossDomainTrackingForm.initInScope;

// Delete when Cookiebot integration is done---
import { showCookieBar } from "@elements/cookie-permissions";
const queryParams = new URLSearchParams(window.location.search);
if (queryParams.has('debugMode')) {
    console.log('DebugMode Param active');
}else{
    if (!_config.admin && !_config.editmode) {
        showCookieBar();
    }
}
// ----------------------------

import * as slider from '@elements/slider';
app.modules.slider = slider.initInScope;

app.modules.gallerySlider = slider.createInitInScope('.js-gallery-slider', {
    nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-left icon-rotate-180"></span></button>',
    prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    slidesToShow: 1,
    infinite: false,
    centerMode: false,
    dots: false,
    variableWidth: true,
});

app.modules.imgSlider = slider.createInitInScope('.js-img-slider', {
    nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-left icon-rotate-180"></span></button>',
    prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    slidesToShow: 1,
    infinite: true,
    centerMode: false,
    dots: false,
    variableWidth: false,
});

app.modules.imgBarSlider = slider.createInitInScope('.js-imgbar-slider', {
    nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-left icon-rotate-180"></span></button>',
    prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
    infinite: false,
    centerMode: false,
    dots: false,
    variableWidth: false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1
            }
        },
    ]
});



(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);


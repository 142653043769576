import {add} from '@elements/scroll-animations';
import {debounce} from 'debounce';

export function initInScope($scope) {
    $scope.find('.js-scroll-marquee').each(function () {
        let $element = $(this);
        let isInverted = $element.hasClass('js-scroll-marquee--inverted');

        createTextCopies($element);

        $(window).on('resize', debounce(() => {
            createTextCopies($element);
        }, 200));

        add(
            $element,
            isInverted ? calculateAnimationProgress : calculateInvertedAnimationProgress,
            setAnimationProgress
        );
    });
}

function createTextCopies($element) {
    let $text = $element.find('.js-scroll-marquee__text').eq(0);
    let neededCopies = Math.ceil(window.innerWidth * 2 / $text[0].getBoundingClientRect().width);
    let markup = '';
    let elementMarkup = $element.html();

    for (var i = 0; i < neededCopies; i++) {
        markup += elementMarkup;
    }

    $element.empty().append(markup);
}

const calculateInvertedAnimationProgress = createCalculateAnimationProgress(true);
const calculateAnimationProgress = createCalculateAnimationProgress(false);

// gets element -> returns number between 0 and 1
function createCalculateAnimationProgress (isInverted) {
    return (element) => {
        const rect = element.getBoundingClientRect();
        const start = 0;
        const end = window.innerHeight + rect.height;
        
        let value = Math.max(
            Math.min(
                (rect.bottom - end) / (start - end),
                1
            ),
            0
        );

        return isInverted ? value : 1 - value;
    }
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    element.style.transform = `translateX(-${progress * 100}%)`;
}

export function initInScope($scope) {
    let $elements = $scope.find('.js-mobile-headroom');
    if (matchMedia('(max-width: 767px)').matches) {
        import('headroom.js').then(({default: Headroom}) => {
            $elements.toArray().forEach(element => {
                let headroom = new Headroom(element);
                headroom.init();
            });
        })
    }
}
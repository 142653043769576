export function initInScope ($scope) {
    $scope.find('.js-main-cta').on('mouseleave', function () {
        let $hoverItems = $(this).find('.js-main-cta__hover-item');
        let $next = $hoverItems.filter('.is-shown').next('.js-main-cta__hover-item');
        $hoverItems.removeClass('is-shown');
        if ($next && $next.length) {
            $next.addClass('is-shown');
        } else {
            $hoverItems.first().addClass('is-shown');
        }
    });
}
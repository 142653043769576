export function init() {
    let $overlay = $('.js-lead-overlay');
    let $open = $('.js-lead-overlay__open');
    let $close = $overlay.find('.js-lead-overlay__close');
    let $input = $overlay.find('.js-lead-overlay__input');
    let $collapse = $overlay.find('.js-lead-overlay__collapse');

    $open.on('click', () => {
        $overlay.addClass('is-visible');
    });

    $close.on('click', () => {
        $overlay.removeClass('is-visible');
    });

    $input.one('focus', () => {
        $collapse.collapse('show');
    });
}
"use strict";

import loadJQueryUi from '@elements/load-jquery-ui';
import {UTCDateToLocalDate, localDateToUTCDate, dateToISOString} from '@elements/date-utils';
import {translate} from '@elements/translations';

function isValidToDate({from, to}) {
    let fromTimestamp = from.getTime();
    let toTimestamp = to.getTime();

    return toTimestamp > fromTimestamp;
}

export function initInScope($scope) {
    let $elements = $scope.find('.js-range-datepicker');

    loadJQueryUi().then(function () {
        $elements.each(function () {
            let $element = $(this);
            let $fromInput = $element.find('.js-range-datepicker__from');
            let $toInput = $element.find('.js-range-datepicker__to');
            let $fromAltInput = $element.find('.js-range-datepicker__from-alt');
            let $toAltInput = $element.find('.js-range-datepicker__to-alt');
            let $picker = $element.find('.js-range-datepicker__datepicker');

            let from = null;
            let to = null;
            let hover = null;


            $picker.datepicker({
                firstDay: 1,
                minDate: UTCDateToLocalDate(new Date()),
                // maxDate: UTCDateToLocalDate(new Date(new Date().getTime() + 365 * 2 * 24 * 60 * 60 * 1000)),
                numberOfMonths: matchMedia('(max-width: 767px)').matches ? 12: 2,
                beforeShowDay: function (localDate) {
                    let date = localDateToUTCDate(localDate);
                    let dateString = dateToISOString(date);
                    let hoverDateString = dateToISOString(hover);

                    if (hover && hoverDateString === dateString) {
                        if (date <= from ) {
                            return [true, 'is-from', translate('datepicker.from')];
                        }

                        if (from && !to) {
                            return [true, 'is-to', translate('datepicker.to')];
                        }

                        if ((from && to) && (date < from || date > to)) {
                            return [true, 'is-from', translate('datepicker.from')];
                        }
                    }

                    if ((from && dateToISOString(from) === dateString)) {
                        return [true, 'is-from', translate('datepicker.from')];
                    }

                    if ((to && dateToISOString(to) === dateString)) {
                        return [true, 'is-to', translate('datepicker.to')];
                    }

                    if (from) {
                        if (to || hover) {
                            let toTimestamp = to ? to.getTime() : hover.getTime();
                            let fromTimestamp = from.getTime();
                            let timestamp = date.getTime();
                            if (timestamp >= fromTimestamp && timestamp <= toTimestamp) {
                                return [true, 'is-between'];
                            }
                        }
                    }

                    return [true, ''];
                },
                onSelect: function (date, datepicker) {
                    let selectedDate = new Date(Date.UTC(datepicker.selectedYear, datepicker.selectedMonth, datepicker.selectedDay));
                    if (from && !to && isValidToDate({from, to: selectedDate})) {
                        to = selectedDate;
                        $toInput.val(selectedDate.toLocaleDateString(_config.lang));
                        $toAltInput.val(formatOutputDate(selectedDate));
                    } else {
                        from = selectedDate;
                        to = null;
                        $fromInput.val(selectedDate.toLocaleDateString(_config.lang));
                        $fromAltInput.val(formatOutputDate(selectedDate));
                        $toInput.val('');
                        $toAltInput.val('');
                    }

                    $element.trigger('selected', {
                        from,
                        to,
                        hover
                    });
                }
            });

            let datepicker = $picker.data('datepicker');

            /* Hover Range */
            if (!matchMedia('(hover: none) and (pointer:coarse)').matches) { // todo mobile
                $element.on('mouseenter', '.ui-datepicker-calendar a', (function (evt) {
                    if (from) {
                        let $target = $(evt.target);
                        let day = +$target.text();
                        let monthOffset = $target.closest('.ui-datepicker-group').index();
                        let currentHover = new Date(Date.UTC(datepicker.drawYear, datepicker.drawMonth + monthOffset, day));

                        if (!hover || currentHover.getTime() !== hover.getTime()) {
                            hover = currentHover;
                            $picker.datepicker('refresh');

                            $element.trigger('hovered', {
                                from,
                                to,
                                hover
                            });
                        }
                    }
                })).on('mouseleave', '.ui-datepicker-calendar', function () {
                    hover = null;
                    $picker.datepicker('refresh');

                    $element.trigger('hovered', {
                        from,
                        to,
                        hover
                    });
                })
            }

            $element.data('range-datepicker', {
                setMinDate: (minDate) => {
                    $picker.datepicker('option', 'minDate', minDate
                        ? UTCDateToLocalDate(minDate)
                        : UTCDateToLocalDate(null)
                    );

                    if (minDate && from < minDate) {
                        from = null;
                        to = null;
                        hover = null;

                        $element.trigger('selected', {
                            from,
                            to,
                            hover
                        });
                    }
                },
                setMaxDate: (maxDate) => {
                    $picker.datepicker('option', 'maxDate', maxDate
                        ? UTCDateToLocalDate(maxDate)
                        : null
                    );

                    if (maxDate && to > maxDate) {
                        from = null;
                        to = null;
                        hover = null;

                        $element.trigger('selected', {
                            from,
                            to,
                            hover
                        });
                    }
                }
            });

            $element.trigger('range-datepicker.init');
        });
    });
}

function formatOutputDate(selectedDate) {
    let month = selectedDate.getUTCMonth() + 1;
    let day = selectedDate.getUTCDate();
    let year = selectedDate.getUTCFullYear();
    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;
    return `${month}/${day}/${year}`;
}

export function setMinDate($element, minDate) {
    onInit($element, () => $element.data('range-datepicker').setMinDate(minDate))
}
export function setMaxDate($element, maxDate) {
    onInit($element, () => $element.data('range-datepicker').setMaxDate(maxDate))
}

function onInit($element, callback) {
    if ($element.data('range-datepicker')) {
        callback();
    } else {
        $element.one('range-datepicker.init', callback);
    }
}
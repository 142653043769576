import {add} from '@elements/scroll-animations';

export function initInScope($scope) {
    add(
        $scope.find('.js-right-to-left-fade-in'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

function calculateAnimationProgress(element) {
    const rect = element.getBoundingClientRect();
    const start = window.innerHeight;
    const end = window.innerHeight / 2 - rect.height / 2;

    return 1 - Math.max(
        Math.min(
            (rect.top - end) / (start - end),
            1
        ),
        0
    );
}

function setAnimationProgress(element, progress) {
    element.style.opacity = progress * .8 + .4;
    element.style.transform = `translateX(${(1- progress) * 5}rem)`;
}
